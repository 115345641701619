import { type ClassValue, clsx } from "clsx";
import type { OptionsType, TmpCookiesObj } from "cookies-next/lib/types";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const capitalize = (str: string) => (str ? str[0].toUpperCase() + str.slice(1) : "");

export const pluralize = (str: string, count: number) => (count === 1 ? str : `${str}s`);

export const fileToUrl = (value?: string | File) => {
  return value && typeof value !== "string" ? URL.createObjectURL(value) : value ?? "";
};

export const camelToSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appendObjectToFormData = (formData: FormData, data: Record<string, any>, parentKey?: string) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    if (Array.isArray(data)) {
      data.forEach((value, index) => {
        appendObjectToFormData(formData, value, `${parentKey}[${index}]`);
      });
    } else {
      Object.keys(data).forEach((key) => {
        appendObjectToFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key);
      });
    }
  } else if (data !== undefined && data !== null) {
    if (data instanceof File || data instanceof Blob) {
      formData.append(parentKey!, data);
    } else {
      formData.append(parentKey!, data.toString());
    }
  }
};

export const formatFileSize = (size: number): string => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let index = 0;
  while (size >= 1024 && index < units.length - 1) {
    size /= 1024;
    index++;
  }
  return `${size} ${units[index]}`;
};

export const getImageDimensions = (url?: string, width?: number, height?: number) => {
  if (url?.includes(".peggy.")) {
    const path = url.substring(0, url.lastIndexOf("/"));
    const fileName = url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("."));
    const extension = url.substring(url.lastIndexOf(".") + 1);
    return `${path}/${fileName}-${width}x${height}.${extension}`;
  }
  return url;
};

export const getUtmParamsFromCookies = (cookies: TmpCookiesObj) => {
  const utmCookies = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "utm_audience",
    "plan_cycle",
    "plan_name",
  ];

  return utmCookies.reduce(
    (acc, key) => {
      if (cookies[key]) {
        acc[key] = cookies[key] as string;
      }
      return acc;
    },
    {} as Record<string, string>
  );
};

export const getCookieOptions = (): OptionsType => {
  let domain = ".peggy.local";

  if ((process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV) === "production") {
    domain = ".peggy.us";
  }

  if ((process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV) === "preview") {
    domain = ".staging.peggy.us";
  }

  const oneMonthFromNow = new Date();

  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

  return { path: "/", domain, secure: true, sameSite: "none", expires: oneMonthFromNow };
};
