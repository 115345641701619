import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { Slot } from "@radix-ui/react-slot";

import { cn } from "~/shared/utils/utils";

const buttonVariants = cva(
  "inline-flex items-center max-h-[48px] h-full justify-center whitespace-nowrap rounded-10 text-sm font-bold ring-offset-background transition-colors duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 w-full",
  {
    variants: {
      variant: {
        primary: "bg-primary-700 text-white hover:bg-primary-800 active:bg-primary-900",
        "primary-outline":
          "border border-primary-700 bg-transparent text-primary-700 hover:text-primary-800 active:text-primary-900 hover:border-primary-800 active:border-primary-900",
        secondary:
          "bg-secondary-400 text-white border border-secondary-400 hover:bg-secondary-500 active:bg-secondary-600",
        "secondary-outline":
          "border border-secondary-400 bg-transparent text-secondary-400 hover:text-secondary-500 active:text-secondary-600 hover:border-secondary-500 active:border-secondary-600",
        ghost: "border border-transparent bg-transparent text-grey-700 hover:bg-grey-100 active:bg-grey-300",
        input: "bg-white rounded-2 border border-grey-300 box-border bg-white text-md leading-6 font-normal",
        "input-rounded": "bg-white rounded-10 border border-grey-300 box-border bg-white text-md leading-6 font-normal",
        link: "w-auto h-auto text-primary-700 hover:text-primary-800 active:text-primary-900",
      },
      size: {
        none: "p-0",
        xs: "p-1 rounded-full w-auto h-auto",
        sm: "px-4 py-3",
        md: "px-5 py-3",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ size, variant, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
