"use client";

import Error from "~/app/error";

interface Props {
  error: Error & { digest?: string };
  reset: () => void;
}

export default function GlobalError(props: Props) {
  return (
    <html>
      <body>
        <Error {...props} />
      </body>
    </html>
  );
}
