"use client";

import { useEffect } from "react";
import Image from "next/image";

import * as Sentry from "@sentry/nextjs";

import logoImage from "~/assets/images/logo.webp";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";

interface Props {
  error: Error & { digest?: string };
  reset: () => void;
}

export default function Error({ error, reset }: Props) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <main className="h-screen gap-10 flex flex-col justify-center items-center p-5">
      <Image src={logoImage} alt="Peggy logo" width={240} height={50} />
      <Heading variant="h3" title="Something went wrong!" />
      <Button variant="secondary" className="max-w-60" onClick={reset}>
        Try again
      </Button>
    </main>
  );
}
