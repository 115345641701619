import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/shared/utils/utils";

const headingVariants = cva("text-grey-700 text-ellipsis overflow-hidden", {
  variants: {
    variant: {
      h1: "text-2xl desktop:text-3xl",
      h2: "text-2xl desktop:text-3xl",
      h3: "text-lg desktop:text-2xl",
      h4: "text-md desktop:text-lg",
      h5: "text-sm desktop:text-md",
      h6: "text-xs desktop:text-sm",
    },
  },
  defaultVariants: {
    variant: "h2",
  },
});

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement> &
  VariantProps<typeof headingVariants> & {
    title: string;
    hasBorder?: boolean;
  };

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ title, children, variant, className, hasBorder, ...props }, ref) => {
    const Comp = variant || "h2";
    return (
      <div
        className={cn("flex items-center", {
          "border-b border-grey-300 pb-2": hasBorder,
        })}
      >
        <Comp ref={ref} className={cn(headingVariants({ variant }), className)} {...props}>
          {title}
        </Comp>
        {children}
      </div>
    );
  }
);
Heading.displayName = "Heading";

export { Heading };
